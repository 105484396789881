.region {
  fill: #fafafa;
  stroke: grey;
}

.region:hover {
  fill: #f15a29;
  /* stroke: darkslategray; */
  /* stroke-width: 2; */
}

.selectedRegion {
  fill: orange;
}

.selectedRegion:hover {
  fill: #f15a29;
  /* stroke: darkslategray; */
  /* stroke-width: 2; */
}

.disabledRegion {
  fill: lightgrey;
  stroke: grey;
}
